exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-es-index-jsx": () => import("./../../../src/pages/es/index.jsx" /* webpackChunkName: "component---src-pages-es-index-jsx" */),
  "component---src-pages-fr-index-jsx": () => import("./../../../src/pages/fr/index.jsx" /* webpackChunkName: "component---src-pages-fr-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-status-page-mdx": () => import("./../../../src/pages/status-page.mdx" /* webpackChunkName: "component---src-pages-status-page-mdx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/CategoryTemplate.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-list-template-jsx": () => import("./../../../src/templates/ListTemplate.jsx" /* webpackChunkName: "component---src-templates-list-template-jsx" */),
  "component---src-templates-mdx-page-template-jsx": () => import("./../../../src/templates/MDXPageTemplate.jsx" /* webpackChunkName: "component---src-templates-mdx-page-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/PageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/PostTemplate.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */)
}

